import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import BgImage from "../assets/images/welcome-bg.jpg";
import LogoSVG from "../assets/images/logo-white-text.svg";
import { useOktaAuth } from "@okta/okta-react";
import { styled } from "@mui/system";
import CenteredProgress from "../components/CenteredProgress";
import DrawerPage from "../components/Drawer";

const MainDiv = styled("div")({
  height: "100vh",
  backgroundImage: `url(${BgImage})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  position: "relative",
});

const LogoImg = styled("img")({
  height: 120,
  paddingLeft: '60px'
});

const SignInButton = styled(Button)({
  color: "white",
  width: 185,
});

const CopyrightDiv = styled("div")({
  position: "absolute",
  left: 24,
  bottom: 24,
});

const Welcome = () => {
  const { oktaAuth, authState } = useOktaAuth();

  if (!authState) {
    return <CenteredProgress></CenteredProgress>;
  }

  console.count("Welcome");

  const onSignInBtnClicked = () => {
    console.count("logging in with redirect url to /login-track");

    oktaAuth.signInWithRedirect({
      originalUri: window.location.href.endsWith("/welcome")
        ? window.location.origin
        : window.location.href,
    });
  };

  if (authState.isAuthenticated) {
    console.count("Redirecting to /");
    return <DrawerPage />;
  }

  return (
    <MainDiv>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        //className={classes.grid}
        spacing={4}
      >
        <Grid item alignItems="center">
          <LogoImg src={LogoSVG} alt="Aedo logo" />
        </Grid>
        <Grid item >
            <SignInButton
              variant="contained"
              onClick={onSignInBtnClicked}
            >
              SIGN IN
            </SignInButton>
       
        </Grid>
      </Grid>
      <CopyrightDiv>
        <Typography>Copyright © 2023 Aedo. All rights reserved.</Typography>
      </CopyrightDiv>
    </MainDiv>
  );
};

export default Welcome;
